import React, { useContext } from 'react';

import log from 'loglevel';

const UserContext = React.createContext(null);

function useUserContext() {
    return useContext(UserContext);
}

// See https://bobbyhadz.com/blog/aws-cognito-user-attributes
// for instructions on setting the custom:Admin property in cognito

// NB - the admin and canGrant custom attributes are only reset on login

function standardizeUser(user) {
    if (!user) return user;
    const standardUser = {};
    if (user.attributes && user.attributes.sub) { // Cognito user
        standardUser.attributes = user.attributes;
        standardUser.attributes.given_name = standardUser.attributes.given_name || '';
        standardUser.attributes.family_name = standardUser.attributes.family_name || '';
        standardUser.attributes.admin = user.attributes['custom:Admin'] === '1';
        standardUser.attributes.canGrant = user.attributes['custom:canGrant'] === '1';
        standardUser.attributes.orgAdmin = user.attributes['custom:OrgAdmin'] === '1';
        if (user.attributes['custom:numEmailAddresses']) {
            standardUser.attributes.numEmailAddresses = parseInt(user.attributes['custom:numEmailAddresses'], 10);
        } else {
            standardUser.attributes.numEmailAddresses = 1;
        }
        standardUser.type = 'Cognito';
    } else if ('username' in user) { // unconfirmed Cognito user
        standardUser.attributes = {
            sub: '',
            email: user.username,
            given_name: '',
            family_name: '',
            admin: false,
            canGrant: false,
            orgAdmin: false,
            numEmailAddresses: 1,
        };
    } else if ('type' in user) {
        // unauthenticated user
        standardUser.type = user.type;
    } else {
        log.error('Unexpected user type');
    }
    return standardUser;
}

export default useUserContext;

export {
    standardizeUser,
    UserContext,
};
