import { useEffect, useMemo, useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import Amplify, { Auth } from 'aws-amplify';
import log from 'loglevel';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { HelmetProvider } from 'react-helmet-async';

import './App.css';
import amplifyConfig from './AmplifyConfig';

import AlertBar from './AlertBar';
import { configGA, initializeGA, usePageViews } from './analyticsTracker';
import apolloClient from './ApolloClient';
import { AppName } from './Constants';
import Footer from './Footer';
import LoadingSpinner from './LoadingSpinner';
import Metadata from './Metadata';
import NavigationBar from './NavigationBar';
import Routes from './Routes';
import { redirectUponSignIn } from './RedirectUponSignIn';
import SideBar from './SideBar';
import { standardizeUser, UserContext } from './UserContext';

// eslint-disable-next-line
process.env.NODE_ENV === 'development'
    ? log.setLevel('trace')
    : log.setLevel('error');

Amplify.configure(amplifyConfig);

// Todo: the way we protect routes is fragile. It relies on
// Amplify posting certain messages to their event bus but those
// don't seem stable since they changed when we fiddled with the
// config that solved the problem of not being able to get the Auth
// credentials after Login.

function isFullyLoggedIn(user) {
    return user && user.attributes
        && user.attributes.sub && user.attributes.sub.length > 0;
}

function App(/* props */) {
    const [alertMessage, setAlertMessage] = useState('');
    const [amplifyUser, setAmplifyUser] = useState(null);
    const [loading, setLoading] = useState(true);

    initializeGA();
    usePageViews();

    useEffect(() => {
        Auth.currentAuthenticatedUser().then((authData) => {
            const standardUser = standardizeUser(authData);
            setAmplifyUser(standardizeUser(standardUser));
            setAlertMessage('');
            configGA();
            redirectUponSignIn();
            setLoading(false);
        }).catch((err) => {
            log.info(err);
            setLoading(false);
        });
    }, []);

    const memoizedUser = useMemo(() => (amplifyUser), [amplifyUser]);

    if (loading) {
        return <LoadingSpinner />;
    }

    const showSideBar = amplifyUser?.attributes?.canGrant || amplifyUser?.attributes?.orgAdmin
        || amplifyUser?.attributes?.admin;

    return (
        <HelmetProvider>
            <Metadata title={AppName} />
            <ApolloProvider client={apolloClient}>
                <UserContext.Provider value={memoizedUser}>
                    <NavigationBar />
                    <AlertBar message={alertMessage} />
                    <Container
                      fluid
                      className="pb-5"
                      // Handles mobile safari issue for background image
                      style={{ overflowX: 'hidden' }}
                    >
                        {isFullyLoggedIn(amplifyUser) ? (
                            <Row className="flex-xl-nowrap" style={{ paddingTop: '0' }}>
                                {showSideBar && (
                                    <Col
                                      as={SideBar}
                                      xs={12}
                                      md={3}
                                      lg={2}
                                      style={{ height: 'auto', zIndex: 1 }}
                                    />
                                )}
                                <Col
                                  className="py-4"
                                  xs={12}
                                  md={showSideBar ? 9 : 12}
                                  lg={showSideBar ? 10 : 12}
                                >
                                    <Routes />
                                </Col>
                            </Row>
                        ) : (
                            <div>
                                <Routes />
                            </div>
                        )}
                    </Container>
                    <Footer />
                </UserContext.Provider>
            </ApolloProvider>
        </HelmetProvider>
    );
}

export default App;
