import {
    ApolloClient,
    InMemoryCache,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';

import { Auth, Signer } from 'aws-amplify';

import log from 'loglevel';

import { standardizeUser } from './UserContext';

const cognitoUri = 'https://r9beci68ki.execute-api.us-east-1.amazonaws.com/LaudGraphQLServer';
const iamUri = 'https://gjbyb03xz4.execute-api.us-east-1.amazonaws.com/LaudGraphQLServer';

async function awsGraphqlFetch(uri, options) {
    let user = { type: 'unauthenticated' };
    try {
        user = await Auth.currentAuthenticatedUser();
    } catch (err) {
        log.error(err);
    }
    const standardUser = standardizeUser(user);
    if (standardUser.expires_at < Date.parse(new Date())) {
        log.info('Credentials expired; logging user out');
        await Auth.signOut({ global: true });
        const search = `?nextPage=${window.location.pathname}${window.location.search}`;
        window.location.assign(`/signIn${search}`);
    } else if (standardUser.type === 'Cognito') {
        const result = await Auth.currentSession();
        const idToken = result.getIdToken();
        const idJwt = idToken.getJwtToken();
        // eslint-disable-next-line no-param-reassign
        options.headers.Authorization = idJwt;
    } else if (standardUser.type === 'unauthenticated') {
        log.info('Unauthenticated user');
        const serviceInfo = {
            region: 'us-east-1', service: 'execute-api',
        };
        const currentCredentials = await Auth.currentCredentials();
        const credentials = {
            access_key: currentCredentials.accessKeyId,
            secret_key: currentCredentials.secretAccessKey,
            session_token: currentCredentials.sessionToken,
        };
        const signable = {
            url: iamUri,
            method: options.method,
            body: options.body,
            headers: { ...options.headers },
        };
        const signedUrl = Signer.signUrl(signable, credentials, serviceInfo);
        return fetch(signedUrl, options);
    } else {
        log.error(`Unknown user type: ${standardUser.type}`);
    }
    return fetch(cognitoUri, options);
}

const apolloClient = new ApolloClient({
    link: new BatchHttpLink({
        uri: cognitoUri,
        fetch: awsGraphqlFetch,
    }),
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    getAwardsForOrganization: {
                        keyArgs: false,
                        merge(existing = [], incoming = []) {
                            const merged = {
                                Awards: [],
                                Paginator: {},
                            };
                            if (existing && existing.Awards) {
                                merged.Awards = existing.Awards.slice(0);
                                merged.Paginator = { ...existing.Paginator };
                                // eslint-disable-next-line no-underscore-dangle
                                merged._typename = existing._typename;
                            }
                            if (incoming) {
                                merged.Awards = merged.Awards.concat(incoming.Awards);
                                merged.Paginator = { ...incoming.Paginator };
                                // eslint-disable-next-line no-underscore-dangle
                                merged._typename = incoming._typename;
                            }
                            return merged;
                        },
                    },
                },
            },
        },
    }),
    connectToDevTools: true,
});

export default apolloClient;
