import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import AdminRoute from './AdminRoute';
import LoadingSpinner from './LoadingSpinner';
import ProtectedRoute from './ProtectedRoute';

const About = lazy(() => import('./About'));
const AcceptAward = lazy(() => import('./AcceptAward'));
const Account = lazy(() => import('./Account'));
const AdminHome = lazy(() => import('./admin/AdminHome'));
const AdminAddLogoImage = lazy(() => import('./admin/AddLogoImage'));
const AdminAddOrganization = lazy(() => import('./admin/AddOrganization'));
const AdminAddTemplate = lazy(() => import('./admin/AddTemplate'));
const AdminBulkGrant = lazy(() => import('./admin/BulkGrant'));
const AdminCalendarImport = lazy(() => import('./admin/CalendarImport'));
const AdminEditLogoImage = lazy(() => import('./admin/EditLogoImage'));
const AdminEditOrganization = lazy(() => import('./admin/EditOrganization'));
const AdminListAwards = lazy(() => import('./admin/ListAwards'));
const AdminListCalendar = lazy(() => import('./admin/ListCalendar'));
const AdminListGrantors = lazy(() => import('./admin/ListGrantors'));
const AdminListGrantRequests = lazy(() => import('./admin/ListGrantRequests'));
const AdminListLogoImages = lazy(() => import('./admin/ListLogoImages'));
const AdminListOrgAdmins = lazy(() => import('./admin/ListOrgAdmins'));
const AdminListOrganizations = lazy(() => import('./admin/ListOrganizations'));
const AdminListTeam = lazy(() => import('./admin/ListTeam'));
const AdminListTemplates = lazy(() => import('./admin/ListTemplates'));
const AdminListUsers = lazy(() => import('./admin/ListUsers'));
const AdminStats = lazy(() => import('./admin/Stats'));
const AdminTeamImport = lazy(() => import('./admin/TeamImport'));
const AdminUserDetail = lazy(() => import('./admin/UserDetail'));
const AdminUserEmails = lazy(() => import('./admin/UserEmails'));
const Api = lazy(() => import('./Api'));
const AwardDetail = lazy(() => import('./AwardDetail'));
const BulkMintForMinters = lazy(() => import('./BulkMintForMinters'));
const Calendar = lazy(() => import('./Calendar'));
const EmailOptOut = lazy(() => import('./EmailOptOut'));
const ForgotUserName = lazy(() => import('./ForgotUserName'));
const GettingStarted = lazy(() => import('./GettingStarted'));
const ManagedMinting = lazy(() => import('./ManagedMinting'));
const Grant = lazy(() => import('./Grant'));
const GrantList = lazy(() => import('./GrantList'));
const GrantStats = lazy(() => import('./GrantStats'));
const Home = lazy(() => import('./Home'));
const HowTo = lazy(() => import('./HowTo'));
const ListLogoImages = lazy(() => import('./admin/ListLogoImages'));
const ListOrganizations = lazy(() => import('./ListOrganizations'));
const Organization = lazy(() => import('./Organization'));
const OrgGrantList = lazy(() => import('./OrgGrantList'));
const OrgStats = lazy(() => import('./OrgStats'));
const Profile = lazy(() => import('./Profile'));
const RequestGranting = lazy(() => import(('./RequestGranting')));
const Revoke = lazy(() => import(('./Revoke')));
const SignIn = lazy(() => import('./SignIn'));
const Team = lazy(() => import('./Team'));
const PlayVideo = lazy(() => import('./PlayVideo'));
const TrophyLanding = lazy(() => import('./TrophyLanding'));
const EngineeringProductivity = lazy(() => import('./EngineeringProductivity'));
const NoMatch = lazy(() => import('./NoMatch'));
const Verification = lazy(() => import('./Verification'));

// NB that /awardimages and /awardtemplates are not routed below but
// are handled by cloudfront, which points both to an S3 bucket used for
// storing award images and templates.

// Todo: upgrade to react-router v.6. This article explains how to update
// ProtectedRoute and AdminRoute:
// https://gist.github.com/mjackson/d54b40a094277b7afdd6b81f51a0393f

function Routes(/* props */) {
    return (
        <Suspense fallback={(
            <div className="mt-5">
                <LoadingSpinner />
            </div>
          )}
        >
            <Switch>
                <AdminRoute
                  exact
                  path="/admin"
                  component={AdminHome}
                />
                <AdminRoute
                  path="/admin/addLogoImage/:organizationId"
                  component={AdminAddLogoImage}
                />
                <AdminRoute
                  exact
                  path="/admin/addOrganization"
                  component={AdminAddOrganization}
                />
                <AdminRoute
                  path="/admin/addTemplate/:organizationId"
                  component={AdminAddTemplate}
                />
                <AdminRoute
                  path="/admin/awards/:organizationId"
                  component={AdminListAwards}
                />
                <AdminRoute
                  exact
                  path="/admin/bulkGrant"
                  component={AdminBulkGrant}
                />
                <AdminRoute
                  path="/admin/calendarImport"
                  component={AdminCalendarImport}
                />
                <AdminRoute
                  path="/admin/calendar/:organizationId/:ownerUserName"
                  component={AdminListCalendar}
                />
                <AdminRoute
                  path="/admin/editLogoImage/:organizationId/:logoImageId"
                  component={AdminEditLogoImage}
                />
                <AdminRoute
                  path="/admin/editOrganization/:organizationId"
                  component={AdminEditOrganization}
                />
                <AdminRoute
                  exact
                  path="/admin/listGrantRequests"
                  component={AdminListGrantRequests}
                />
                <AdminRoute
                  path="/admin/listLogoImages/:organizationId"
                  admin
                  component={AdminListLogoImages}
                />
                <AdminRoute
                  exact
                  path="/admin/listOrganizations"
                  component={AdminListOrganizations}
                />
                <AdminRoute
                  path="/admin/minters/:organizationId"
                  component={AdminListGrantors}
                />
                <AdminRoute
                  path="/admin/stats"
                  component={AdminStats}
                />
                <AdminRoute
                  path="/admin/team/:organizationId/:managerUserName"
                  component={AdminListTeam}
                />
                <AdminRoute
                  path="/admin/organizationAdmins/:organizationId"
                  component={AdminListOrgAdmins}
                />
                <AdminRoute
                  path="/admin/teamImport"
                  component={AdminTeamImport}
                />
                <AdminRoute
                  path="/admin/templates/:organizationId"
                  component={AdminListTemplates}
                />
                <AdminRoute
                  path="/admin/user/:userName"
                  component={AdminUserDetail}
                />
                <AdminRoute
                  path="/admin/userEmails/:userName"
                  component={AdminUserEmails}
                />
                <AdminRoute
                  exact
                  path="/admin/users"
                  component={AdminListUsers}
                />
                <ProtectedRoute
                  exact
                  path="/"
                  component={Home}
                />
                <ProtectedRoute
                  path="/acceptaward/:awardId"
                  component={AcceptAward}
                />
                <ProtectedRoute
                  exact
                  path="/account"
                  component={Account}
                />
                <ProtectedRoute
                  path="/addLogoImage/:organizationId"
                  component={AdminAddLogoImage}
                />
                <ProtectedRoute
                  path="/editLogoImage/:organizationId/:logoImageId"
                  component={AdminEditLogoImage}
                />
                <ProtectedRoute
                  exact
                  path="/api"
                  component={Api}
                />
                <ProtectedRoute
                  exact
                  path="/bulkMint"
                  component={BulkMintForMinters}
                />
                <ProtectedRoute
                  exact
                  path="/calendar"
                  component={Calendar}
                />
                <ProtectedRoute
                  exact
                  path="/grant"
                  component={Grant}
                />
                <ProtectedRoute
                  exact
                  path="/grantlist"
                  component={GrantList}
                />
                <ProtectedRoute
                  path="/minters/:organizationId"
                  component={AdminListGrantors}
                />
                <ProtectedRoute
                  exact
                  path="/grantStats"
                  component={GrantStats}
                />
                <ProtectedRoute
                  path="/listLogoImages/:organizationId"
                  component={ListLogoImages}
                  admin={false}
                />
                <ProtectedRoute
                  exact
                  path="/listOrganizations"
                  component={ListOrganizations}
                />
                <ProtectedRoute
                  path="/organizationAwards/:organizationId"
                  component={OrgGrantList}
                />
                <ProtectedRoute
                  path="/organizationStats/:organizationId"
                  component={OrgStats}
                />
                <ProtectedRoute
                  path="/organization/:organizationId"
                  component={Organization}
                />
                <ProtectedRoute
                  exact
                  path="/PlayVideo"
                  component={PlayVideo}
                />
                <ProtectedRoute
                  path="/profile/:profileId"
                  component={Profile}
                />
                <ProtectedRoute
                  exact
                  path="/revoke"
                  component={Revoke}
                />
                <ProtectedRoute
                  exact
                  path="/team"
                  component={Team}
                />
                <ProtectedRoute
                  path="/verification/:resourceName/:verificationCode"
                  component={Verification}
                />
                <Route
                  exact
                  path="/about"
                >
                    <About />
                </Route>
                <Route
                  path="/award/:awardId/:nftLocation"
                >
                    <AwardDetail />
                </Route>
                <Route
                  exact
                  path="/landingPage"
                >
                    <About />
                </Route>
                <Route
                  exact
                  path="/howto"
                >
                    <HowTo />
                </Route>
                <Route
                  exact
                  path="/requestGranting"
                >
                    <RequestGranting />
                </Route>
                <Route
                  exact
                  path="/TrophyLanding"
                >
                    <TrophyLanding />
                </Route>
                <Route
                  exact
                  path="/EngineeringProductivity"
                >
                    <EngineeringProductivity />
                </Route>
                <Route
                  exact
                  path="/emailUnsubscribe"
                >
                    <EmailOptOut />
                </Route>
                <Route
                  exact
                  path="/forgotusername"
                >
                    <ForgotUserName />
                </Route>
                <Route
                  exact
                  path="/gettingStarted"
                >
                    <GettingStarted />
                </Route>
                <Route
                  exact
                  path="/managedMinting"
                >
                    <ManagedMinting />
                </Route>
                <Route
                  exact
                  path="/signIn"
                >
                    <SignIn />
                </Route>
                <Route
                  path="*"
                >
                    <NoMatch />
                </Route>
            </Switch>
        </Suspense>
    );
}

export default Routes;
