import React from 'react';

import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import './custom.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

const nakedDomain = 'givelaud.com';
const wwwDomain = `www.${nakedDomain}`;

if (window.location.hostname === nakedDomain) {
    window.location.assign(window.location.href.replace(nakedDomain, wwwDomain));
}

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: 'https://5db4d5ca80874cb991737432f4898013@o526321.ingest.sentry.io/5641704',
        integrations: [new Integrations.BrowserTracing()],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <Sentry.ErrorBoundary
          fallback={<p>Sorry, something went wrong. Please try again.</p>}
        >
            <Router>
                <App />
            </Router>
        </Sentry.ErrorBoundary>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
