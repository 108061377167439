import { memo } from 'react';

import PropTypes from 'prop-types';

import Image from 'react-bootstrap/Image';

import { MediaPath } from './Constants';

const RingSpinner = `${MediaPath}ring-loader-70px-2x.gif`;

function LoadingSpinner(props) {
    let { message } = props;
    const { noMessage, className } = props;
    let classforMessage = '';

    if (noMessage) {
        classforMessage = 'sr-only';
        message = '';
    }

    return (
        <div className={className}>
            <Image
              src={RingSpinner}
              height="35px"
              width="35px"
            />
            &nbsp;
            <span className={classforMessage}>{message}</span>
        </div>
    );
}

LoadingSpinner.propTypes = {
    className: PropTypes.string,
    message: PropTypes.string,
    noMessage: PropTypes.bool,
};

LoadingSpinner.defaultProps = {
    className: '',
    message: 'Loading...',
    noMessage: false,
};

export default memo(LoadingSpinner);
