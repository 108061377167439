import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { AppName, MediaPath } from './Constants';
import SignOutButton from './SignOutButton';
import useUserContext from './UserContext';

const LaudLogo = `${MediaPath}Laud-Logo-Negative-2x.png`;

function renderUserData(context) {
    if (context) {
        let givenName = '';
        let familyName = '';
        let name = '';
        if (context.attributes) {
            if (context.attributes.given_name) {
                givenName = context.attributes.given_name;
            }
            if (context.attributes.family_name) {
                familyName = context.attributes.family_name;
            }
        }

        // Below handles edge case where amplify allows empty givenName and familyname
        // This lets the user access their account to change it otherwise the account
        // change UX is not visible in the UX unless the link is known.
        // Will remove once we have a fix here for amplify

        if (givenName === '' && familyName === '') {
            if (context.attributes && context.attributes.email) {
                name = context.attributes.email;
            } else {
                name = `${AppName} User`;
            }
        } else if (givenName || familyName) {
            name = `${givenName} ${familyName}`;
            name = name.trim();
        }
        return (
            <>
                {name && (
                    <Nav.Link
                      className="me-2"
                      href="/account"
                    >
                        {name}
                    </Nav.Link>
                )}
                <SignOutButton />
            </>
        );
    }
    const reload = () => window.location.assign('/signIn');
    return (
        <Button
          variant="danger"
          style={{ color: 'white' }}
          onClick={reload}
        >
            Sign In
        </Button>
    );
}

function NavigationBar(/* props */) {
    const context = useUserContext();
    return (
        <Navbar
          fixed="top"
          bg="primary"
          variant="dark"
          expand="md"
          className="align-items-center pe-4"
          style={{ width: 'auto' }}
        >
            <Container fluid>
                <Navbar.Brand href="/">
                    <img
                      style={{
                          height: '32px',
                          width: '134px',
                      }}
                      src={LaudLogo}
                      alt={AppName}
                    />
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    { context && (
                        <>
                            <Nav />
                        </>
                    )}
                    <Nav className="ms-auto justify-content-end">
                        {renderUserData(context)}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

NavigationBar.propTypes = {
    // No props are passed to NavigationBar
};

export default NavigationBar;
