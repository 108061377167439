import { memo } from 'react';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { CompanyName } from './Constants';
import useUserContext from './UserContext';

function Footer(/* props */) {
    const user = useUserContext();
    const isOrgAdmin = user?.attributes?.orgAdmin || false;
    const canGrant = user?.attributes?.canGrant || false;

    return (
        <footer className="mt-2 fixed-bottom">
            <Navbar bg="light" color="dark">
                <Container fluid>
                    <Nav className="me-auto">
                        <Nav.Link href="/about">
                            About
                        </Nav.Link>
                        <Nav.Link href="/gettingstarted">
                            Getting Started
                        </Nav.Link>
                        <Nav.Link href="/howto">
                            How To
                        </Nav.Link>
                        {(isOrgAdmin || canGrant) && (
                            <Nav.Link href="/api">
                                API
                            </Nav.Link>
                        )}
                    </Nav>
                    <Nav>
                        <Navbar.Text>
                            Copyright: ©
                            { ' ' }
                            {CompanyName}
                        </Navbar.Text>
                    </Nav>
                </Container>
            </Navbar>
        </footer>
    );
}

export default memo(Footer);
