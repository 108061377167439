// We use cloudfront to map givelaud.com/awardimages to the laudawards S3
// bucket and directly into the awardimages folder. We do the same for
// templates.

const ProdDomain = 'https://www.givelaud.com';
const AppName = 'Laud';
const ArweaveDomain = 'https://arweave.net';
const ArweaveBlockExplorer = 'https://viewblock.io/arweave/tx';
const AwardBucketName = 'laudawards';
const AwardLogoPath = `${ProdDomain}/logoImages/vajH3fdnRPQoTjR4XqNHk.png`;
const AwardImagePath = 'awardimages/';
const AwardPath = `${ProdDomain}/awardimages/`;
const AwardTemplatePath = 'awardtemplates/';
const CompanyName = 'Erdos Labs';
const Domain = process.env.NODE_ENV !== 'development'
    ? ProdDomain : `http://${window.location.host}`;
const EmailPath = 'https://laudawards.s3.amazonaws.com/emailimages/';
const LogoImagePath = 'logoImages/';
const LogoPath = `${ProdDomain}/logoImages/`;
const MediaPath = `${ProdDomain}/static/media/`;
const SplinesPath = `${ProdDomain}/splines/`;

exports.AppName = AppName;
exports.ArweaveDomain = ArweaveDomain;
exports.ArweaveBlockExplorer = ArweaveBlockExplorer;
exports.AwardBucketName = AwardBucketName;
exports.AwardLogoPath = AwardLogoPath;
exports.AwardPath = AwardPath;
exports.AwardImagePath = AwardImagePath;
exports.AwardTemplatePath = AwardTemplatePath;
exports.CompanyName = CompanyName;
exports.Domain = Domain;
exports.EmailPath = EmailPath;
exports.LogoImagePath = LogoImagePath;
exports.LogoPath = LogoPath;
exports.MediaPath = MediaPath;
exports.SplinesPath = SplinesPath;
