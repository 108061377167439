import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import useUserContext from './UserContext';

function ProtectedRoute(props) {
    const {
        component: Component, computedMatch, location, path, ...rest
    } = props;
    const user = useUserContext();
    const isAuthenticated = (user && user.attributes && user.attributes.sub);
    const destination = path === '/' ? '/about' : '/signIn';
    const search = `?nextPage=${location.pathname}${location.search}`;
    return isAuthenticated ? (
        <Component match={computedMatch} {...rest} />
    ) : (
        <Redirect to={{ pathname: destination, search }} />
    );
}

ProtectedRoute.propTypes = {
    // eslint-disable-next-line
    component: PropTypes.object.isRequired,
    // eslint-disable-next-line
    computedMatch: PropTypes.object,
    location: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    path: PropTypes.string,
};

ProtectedRoute.defaultProps = {
    computedMatch: null,
    location: '',
    path: '',
};

export default ProtectedRoute;
