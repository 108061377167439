const region = 'us-east-1';

// Laud user pool & identity pool
const amplifyConfig = {
    aws_project_region: region,
    aws_cognito_identity_pool_id:
        'us-east-1:8fc7cade-4dc4-4121-bf41-ef2129750ffa',
    aws_user_pools_id: 'us-east-1_QwBTyQHJj',
    aws_cognito_region: region,
    aws_user_pools_web_client_id: '2njtvln5ureosm5u92i259kp9k',
    aws_cognito_password_protection_settings: {
        passwordPolicyMinLength: 8,
        passwordPolicyCharacters: [
            'REQUIRES_LOWERCASE',
            'REQUIRES_NUMBERS',
            'REQUIRES_SYMBOLS',
            'REQUIRES_UPPERCASE',
        ],
    },
};

export default amplifyConfig;
