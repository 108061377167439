import { gql, useQuery } from '@apollo/client';

const acceptAwardMutation = gql`
    mutation acceptAward($AwardId: String!, $UserName: String!, $Accepted: Boolean!) {
        acceptAward(AwardId: $AwardId, UserName: $UserName, Accepted: $Accepted) {
            AwardId
            UserName
            Accepted
            AcceptDate
            NFTPermalink
        }
    }
`;

const addEmailMutation = gql`
    mutation addEmail($UserName: String!, $Email: String!) {
        addEmail(UserName: $UserName, Email: $Email) {
            UserName
            Email
        }
    }
`;

const addEventMutation = gql`
    mutation addEvent(
        $OwnerUserName: String!,
        $EventId: String!,
        $EventDate: String!,
        $OrganizationId: String!,
        $EventName: String!,
        $TargetEmail: String!,
        $Recurrence: String!) {
        addEvent(
            OwnerUserName: $OwnerUserName,
            EventId: $EventId,
            EventDate: $EventDate,
            OrganizationId: $OrganizationId,
            EventName: $EventName,
            TargetEmail: $TargetEmail,
            Recurrence: $Recurrence) {
            OwnerUserName
            EventId
            EventDate
            OrganizationId
            EventName
            TargetEmail
            Recurrence
        }
    }
`;

const addGrantorMutation = gql`
    mutation addGrantor($OrganizationId: String!, $Email: String!) {
        addGrantor(OrganizationId: $OrganizationId, Email: $Email) {
            OrganizationId
            UserName
        }
    }
`;

const addLogoImageMutation = gql`
    mutation addLogoImage(
        $OrganizationId: String!,
        $LogoImageId: String!,
        $LogoImageName: String!,
        $LogoImageLocation: String!) {
        addLogoImage(
            OrganizationId: $OrganizationId,
            LogoImageId: $LogoImageId,
            LogoImageName: $LogoImageName,
            LogoImageLocation: $LogoImageLocation,           
        ) {
            OrganizationId
            LogoImageId
            LogoImageName
            LogoImageLocation
        }
    }
`;

const addNomination = gql`
    mutation addNomination(
        $NominationId: String!,
        $NomineeEmail: String!,
        $MinterEmail: String!,
        $Reason: String!,
        $NominatorEmail: String!) {
        addNomination(
            NominationId: $NominationId,
            NomineeEmail: $NomineeEmail,
            MinterEmail: $MinterEmail,
            Reason: $Reason,
            NominatorEmail: $NominatorEmail,
        ) {
            NominationId
            NomineeEmail
            MinterEmail
            Reason
            NominatorEmail
        }
    }
`;

const addOrgAdminMutation = gql`
    mutation addOrgAdmin($OrganizationId: String!, $Email: String!) {
        addOrgAdmin(OrganizationId: $OrganizationId, Email: $Email) {
            OrganizationId
            UserName
        }
    }
`;

const addTeamMemberMutation = gql`
    mutation addTeamMember($ManagerUserName: String!, $Email: String!, $OrganizationId: String!) {
        addTeamMember(
            ManagerUserName: $ManagerUserName,
            Email: $Email,
            OrganizationId: $OrganizationId) {
            ManagerUserName
            Email
            OrganizationId
        }
    }
`;

const createOrganizationMutation = gql`
    mutation createOrganization(
        $OrganizationId: String!,
        $OrganizationName: String!,
        $Address1: String!,
        $Address2: String!,
        $City: String!,
        $State: String!,
        $PostalCode: String!,
        $Country: String!,
        $ContactEmail: String!,
        $Verified: Boolean!,
        $VerificationNotes: String!,
        $Active: Boolean!,
        $LicenseType: String!,
        $SlackWebhookUrl: String!,
        ) {
        createOrganization(
            OrganizationId: $OrganizationId,
            OrganizationName: $OrganizationName,
            Address1: $Address1,
            Address2: $Address2,
            City: $City,
            State: $State,
            PostalCode: $PostalCode,
            Country: $Country,
            ContactEmail: $ContactEmail,
            Verified: $Verified,
            VerificationNotes: $VerificationNotes,
            Active: $Active,
            LicenseType: $LicenseType,
            SlackWebhookUrl: $SlackWebhookUrl) {
            OrganizationId
            OrganizationName
            Address1
            Address2
            City
            State
            PostalCode
            Country
            ContactEmail
            Verified
            Active
            PublicKey
            VerificationNotes
            LicenseType
            SlackWebhookUrl
        }
    }
`;

const createConsumerProductInterestMutation = gql`
    mutation createConsumerProductInterest(
        $Email: String!,
        $Comment: String,
    ) {
        createConsumerProductInterest(
            Email: $Email,
            Comment: $Comment,
        ) {
            Email
            Comment
        }
    }
`;

const createGrantRequestMutation = gql`
    mutation createGrantRequest(
        $UserName: String!,
        $RequestId: String!,
        $OrganizationId: String!,
        $GivenName: String!,
        $FamilyName: String!,
        $Phone: String!,
        $Email: String!,
        $Title: String!,
        $LinkedIn: String,
        $Completed: Boolean!,
        $CreatedDate: String!,
        $Referrer: String!,
    ) {
        createGrantRequest(
            UserName: $UserName,
            RequestId: $RequestId,
            OrganizationId: $OrganizationId,
            GivenName: $GivenName,
            FamilyName: $FamilyName,
            Phone: $Phone,
            Email: $Email,
            Title: $Title,
            LinkedIn: $LinkedIn,
            Completed: $Completed,
            CreatedDate: $CreatedDate,
            Referrer: $Referrer,
        ) {
            UserName
            RequestId
            OrganizationId
            GivenName
            FamilyName
            Phone
            Email
            Title
            LinkedIn
            Completed
            CreatedDate
            Referrer
        }
    }
`;

// eslint-disable-next-line no-unused-vars
const ImageFieldInput = gql`
    input TextFieldInput {
        left: Int!
        top: Int!
    }
`;

// eslint-disable-next-line no-unused-vars
const TextFieldInput = gql`
    input TextFieldInput {
        left: Int!
        top: Int!
        height: Int
        width: Int
        fontFamily: String!
        fontSize: Int!
        stroke: String!
        angle: Int
    }
`;

const createTemplateMutation = gql`
    mutation createTemplate(
        $TemplateId: String!,
        $OrganizationId: String!,
        $TemplateName: String!,
        $NFTLocation: String!,
        $Height: Int!,
        $Width: Int!,
        $AwardDescription: TextFieldInput!,
        $RecipientName: TextFieldInput!,
        $GrantDate: TextFieldInput!,
        $AwardName: TextFieldInput!,
        $NFTSerialNumber: TextFieldInput!,
        $OrganizationLogo: ImageFieldInput!,
        $LaudLogo: ImageFieldInput!) {
        createTemplate(
            TemplateId: $TemplateId,
            OrganizationId: $OrganizationId,
            TemplateName: $TemplateName,
            NFTLocation: $NFTLocation,
            Height: $Height,
            Width: $Width,
            AwardDescription: $AwardDescription,
            RecipientName: $RecipientName,
            GrantDate: $GrantDate,
            AwardName: $AwardName,
            NFTSerialNumber: $NFTSerialNumber,
            OrganizationLogo: $OrganizationLogo,
            LaudLogo: $LaudLogo,
        ) {
            TemplateId
            OrganizationId
            TemplateName
            NFTLocation
            Height
            Width
            AwardDescription {
                left
                top
                height
                width
                fontFamily
                fontSize
                stroke
                angle
            }
            RecipientName {
                left
                top
                height
                width
                fontFamily
                fontSize
                stroke
                angle
            }
            GrantDate {
                left
                top
                height
                width
                fontFamily
                fontSize
                stroke
                angle
            }
            AwardName {
                left
                top
                height
                width
                fontFamily
                fontSize
                stroke
                angle
            }
            NFTSerialNumber {
                left
                top
                height
                width
                fontFamily
                fontSize
                stroke
                angle
            }
            OrganizationLogo {
                left
                top
                height
                width
            }
            LaudLogo {
                left
                top
                height
                width
            }
        }
    }
`;

const deleteEmailMutation = gql`
    mutation deleteEmail(
        $UserName: String!,
        $Email: String!) {
        deleteEmail(
            UserName: $UserName,
            Email: $Email) {
            UserName
            Email
        }
    }
`;

const deleteEventMutation = gql`
    mutation deleteEvent(
        $OwnerUserName: String!,
        $EventId: String!) {
        deleteEvent(
            OwnerUserName: $OwnerUserName,
            EventId: $EventId) {
            OwnerUserName
            EventId
        }
    }
`;

const deleteFutureMinterMutation = gql`
    mutation deleteGrantor(
        $Email: String!,
        $OrganizationId: String!) {
        deleteFutureMinter(
            Email: $Email,
            OrganizationId: $OrganizationId) {
            Email
            OrganizationId
        }
    }
`;

const deleteGrantorMutation = gql`
    mutation deleteGrantor(
        $UserName: String!,
        $OrganizationId: String!) {
        deleteGrantor(
            UserName: $UserName,
            OrganizationId: $OrganizationId) {
            UserName
            OrganizationId
        }
    }
`;

const deleteLogoImageMutation = gql`
    mutation deleteLogoImage(
        $OrganizationId: String!,
        $LogoImageId: String!) {
        deleteLogoImage(
            OrganizationId: $OrganizationId,
            LogoImageId: $LogoImageId,
        ) {
            OrganizationId
            LogoImageId
        }
    }
`;

const deleteOrgAdminMutation = gql`
    mutation deleteOrgAdmin(
        $UserName: String!,
        $OrganizationId: String!) {
        deleteOrgAdmin(
            UserName: $UserName,
            OrganizationId: $OrganizationId) {
            UserName
            OrganizationId
        }
    }
`;

const deleteOrganizationMutation = gql`
    mutation deleteOrganization(
        $OrganizationId: String!) {
        deleteOrganization(
            OrganizationId: $OrganizationId,
        ) {
            OrganizationId
        }
    }  
`;

const deleteTeamMemberMutation = gql`
    mutation deleteTeamMember($ManagerUserName: String!, $Email: String!, $OrganizationId: String!) {
        deleteTeamMember(
            ManagerUserName: $ManagerUserName,
            Email: $Email,
            OrganizationId: $OrganizationId) {
            ManagerUserName
            Email
            OrganizationId
        }
    }
`;

const deleteTemplateMutation = gql`
    mutation deleteTemplate(
        $TemplateName: String!,
        $OrganizationId: String!) {
        deleteTemplate(
            TemplateName: $TemplateName,
            OrganizationId: $OrganizationId) {
            TemplateName
        }
    }
`;

const grantAwardMutation = gql`
    mutation grantAward(
        $AwardId: String!,
        $AwardType: String!,
        $RecipientName: String!,
        $GrantDate: String!,
        $AwardDescription: String!,
        $Organization: String!,
        $GrantorEmail: String!,
        $RecipientEmail: String!,
        $NFTLocation: String!,
        $NFTThumbnail: String!,
        $OrganizationId: String!,
        $AwardName: String!,
        $GrantorName: String,
        $Note: String,
        $CC: [String],
        $BCC: [String],
        $Context: String!,
        $ContextURL: String!) {
        grantAward(
            AwardId: $AwardId,
            AwardType: $AwardType,
            RecipientName: $RecipientName,
            GrantDate: $GrantDate,
            AwardDescription: $AwardDescription,
            Organization: $Organization,
            GrantorEmail: $GrantorEmail,
            RecipientEmail: $RecipientEmail,
            NFTLocation: $NFTLocation,
            NFTThumbnail: $NFTThumbnail,
            OrganizationId: $OrganizationId,
            AwardName: $AwardName,
            GrantorName: $GrantorName,
            Note: $Note,
            CC: $CC,
            BCC: $BCC,
            Context: $Context,
            ContextURL: $ContextURL) {
            AwardId,
            AwardType,
            RecipientName,
            GrantDate,
            AwardDescription,
            Organization,
            GrantorEmail,
            GrantorUserName,
            GrantorName,
            RecipientEmail,
            NFTLocation,
            NFTThumbnail,
            OrganizationId,
            Signature,
            Revoked,
            RevocationReason,
            RevocationDate,
            AwardName,
            Note,
            CC,
            BCC,
            Context,
            ContextURL,
        }
    }  
`;

const deactivateEmailMutation = gql`
    mutation deactivateEmail($UserName: String!, $Email: String!) {
        deactivateEmail(UserName: $UserName, Email: $Email) {
            UserName
            Email
        }
    }
`;

const deleteUserMutation = gql`
    mutation deleteUser($UserName: String!) {
        deleteUser(UserName: $UserName) {
            UserName
        }
    }
`;

const emailOptOutMutation = gql`
    mutation addEmailOptOut($Email: String!) {
        addEmailOptOut(Email: $Email) {
            Email
        }
    }
`;

const markGrantRequestCompleted = gql`
    mutation markGrantRequestCompleted($RequestId: String!, $UserName: String!) {
        markGrantRequestCompleted(RequestId: $RequestId, UserName: $UserName) {
            UserName
            RequestId
            Completed
        }
    }
`;

const revokeAwardMutation = gql`
    mutation revokeAward($AwardId: String!, $RevocationReason: String!) {
        revokeAward(AwardId: $AwardId, RevocationReason: $RevocationReason) {
            AwardId
        }
    }
`;

const sendAndCollectEmailMutation = gql`
    mutation sendAndCollectEmail(
        $AwardId: String!,
        $NFTLocation: String!,
        $NFTThumbnail: String!,
        $Recipients: [String],
        $Note: String!) {
        sendAndCollectEmail(
            AwardId: $AwardId,
            NFTLocation: $NFTLocation,
            NFTThumbnail: $NFTThumbnail,
            Recipients: $Recipients,
            Note: $Note,
        ) {
            AwardId
        }
    }
`;

const uploadToArweaveMutation = gql`
    mutation uploadToArweave($AwardId: String!) {
        uploadToArweave(AwardId: $AwardId) {
            AwardId
        }
    }
`;

const verifyEmailMutation = gql`
    mutation verifyEmail($UserName: String!, $Email: String!) {
        verifyEmail(UserName: $UserName, Email: $Email) {
            UserName
            Email
        }
    }
`;

const updateUserEmailMutation = gql`
    mutation updateUserEmail(
        $UserName: String!,
        $Email: String!,
    ) {
        updateUserEmail(
            UserName: $UserName,
            Email: $Email,
        ) {
            UserName
            Email
        }
    }
`;

const updateUserNameMutation = gql`
    mutation updateUserName(
        $UserName: String!,
        $GivenName: String!,
        $FamilyName: String!,
    ) {
        updateUserName(
            UserName: $UserName,
            GivenName: $GivenName,
            FamilyName: $FamilyName,
        ) {
            UserName
            GivenName
            FamilyName
        }
    }
`;

const updateUserOptOutMutation = gql`
    mutation updateUserOptOut(
        $UserName: String!,
        $EmailOptOut: Boolean!,
    ) {
        updateUserOptOut(
            UserName: $UserName,
            EmailOptOut: $EmailOptOut,
        ) {
            UserName
            EmailOptOut
        }
    }
`;

const awardsQuery = gql`
    query getAwards(
        $UserName: String!,
        $AcceptedOnly: Boolean!) {
        getAwardsFromUserName(UserName: $UserName, AcceptedOnly: $AcceptedOnly) {
            UserName
            AwardId
            Accepted
            AwardType
            RecipientName
            RecipientEmail
            GrantDate
            AwardDescription
            Organization
            OrganizationId
            GrantorEmail
            GrantorUserName
            GrantorName
            AcceptDate
            NFTLocation
            NFTThumbnail
            Signature
            Revoked
            RevocationReason
            RevocationDate
            NFTPermalink
            AwardName
            Note
            Context
            ContextURL
        }
    }
`;

const awardWithPKQuery = gql`
    query getAwardFromIdWithPK(
        $AwardId: String!) {
        getAwardFromId(AwardId: $AwardId) {
            UserName
            AwardId
            Accepted
            AwardType
            RecipientName
            RecipientEmail
            GrantDate
            AwardDescription
            Organization
            OrganizationId
            GrantorEmail
            GrantorUserName
            GrantorName
            AcceptDate
            NFTLocation
            NFTThumbnail
            Signature
            Revoked
            RevocationReason
            RevocationDate
            NFTPermalink
            AwardName
            Note
            Context
            ContextURL
            Org {
                PublicKey
            }
        }
    }
`;

const awardQuery = gql`
    query getAwardFromId(
        $AwardId: String!) {
        getAwardFromId(AwardId: $AwardId) {
            UserName
            AwardId
            Accepted
            AwardType
            RecipientName
            RecipientEmail
            GrantDate
            AwardDescription
            Organization
            OrganizationId
            GrantorEmail
            GrantorUserName
            GrantorName
            AcceptDate
            NFTLocation
            NFTThumbnail
            Signature
            Revoked
            RevocationReason
            RevocationDate
            NFTPermalink
            AwardName
            Note
            Context
            ContextURL
        }
    }
`;

function useAwardQuery(awardId, getPublicKey) {
    return useQuery(
        getPublicKey ? awardWithPKQuery : awardQuery,
        {
            variables: {
                AwardId: awardId,
            },
        },
    );
}

function useAwardsQuery(userName, acceptedOnly) {
    return useQuery(
        awardsQuery,
        {
            variables: {
                UserName: userName,
                AcceptedOnly: acceptedOnly,
            },
            notifyOnNetworkStatusChange: true,
        },
    );
}

const awardsByOrganizationQuery = gql`
    query getAwardsByOrganization($UserName: String!) {
        getAwardsByOrganization(UserName: $UserName) {
            UserName
            AwardId
            Accepted
            AwardType
            RecipientName
            RecipientEmail
            GrantDate
            AwardDescription
            Organization
            OrganizationId
            GrantorEmail
            GrantorUserName
            GrantorName
            AcceptDate
            NFTLocation
            NFTThumbnail
            Signature
            Revoked
            RevocationReason
            RevocationDate
            RevokerUserName
            NFTPermalink
            AwardName
            Note
            Context
            ContextURL
        }
    }
`;

function useAwardsByOrganizationQuery(userName) {
    return useQuery(
        awardsByOrganizationQuery,
        {
            variables: {
                UserName: userName,
            },
        },
    );
}

const awardsForOrganizationQuery = gql`
    query getAwardsForOrganization(
        $OrganizationId: String!,
        $ExclusiveStartKey: JSON) {
        getAwardsForOrganization(
            OrganizationId: $OrganizationId,
            ExclusiveStartKey: $ExclusiveStartKey) {
            Awards {
                UserName
                AwardId
                Accepted
                AwardType
                RecipientName
                RecipientEmail
                GrantDate
                AwardDescription
                Organization
                OrganizationId
                GrantorEmail
                GrantorUserName
                GrantorName
                AcceptDate
                NFTLocation
                NFTThumbnail
                Signature
                Revoked
                RevocationReason
                RevocationDate
                RevokerUserName
                NFTPermalink
                AwardName
                Note
                Context
                ContextURL
            }
            Paginator {
                LastEvaluatedKey
            }
        }
    }`;

function useAwardsForOrganizationQuery(organizationId, exclusiveStartKey) {
    return useQuery(
        awardsForOrganizationQuery,
        {
            variables: {
                OrganizationId: organizationId,
                ExclusiveStartKey: exclusiveStartKey,
            },
        },
    );
}

const emailQuery = gql`
    query getEmailsFromUserName(
        $UserName: String!) {
        getEmailsFromUserName(UserName: $UserName) {
            UserName
            Email
            Verified
            Active
        }
    }
`;

function useEmailQuery(userName, pollInterval) {
    return useQuery(
        emailQuery,
        {
            variables: {
                UserName: userName,
            },
            pollInterval: pollInterval || 0,
        },
    );
}

const getSignatureQuery = gql`
    query getSignature(
        $AwardId: String!,
        $AwardType: String!,
        $RecipientName: String!,
        $GrantDate: String!,
        $AwardDescription: String!,
        $Organization: String!,
        $GrantorEmail: String!,
        $RecipientEmail: String!,
        $NFTLocation: String!,
        $NFTThumbnail: String!,
        $OrganizationId: String!,
        $AwardName: String!,
        $GrantorName: String,
        $Note: String!,
        $CC: [String],
        $BCC: [String],
        $Context: String!,
        $ContextURL: String!) {
        getSignature(
            AwardId: $AwardId,
            AwardType: $AwardType,
            RecipientName: $RecipientName,
            GrantDate: $GrantDate,
            AwardDescription: $AwardDescription,
            Organization: $Organization,
            GrantorEmail: $GrantorEmail,
            RecipientEmail: $RecipientEmail,
            NFTLocation: $NFTLocation,
            NFTThumbnail: $NFTThumbnail,
            OrganizationId: $OrganizationId,
            AwardName: $AwardName,
            GrantorName: $GrantorName,
            Note: $Note,
            CC: $CC,
            BCC: $BCC,
            Context: $Context,
            ContextURL: $ContextURL) {
            AwardId,
            Signature,
        }
    }
`;

const phoneQuery = gql`
    query getPhonesFromUserName(
        $UserName: String!) {
        getPhonesFromUserName(UserName: $UserName) {
            UserName
            Phone
            Verified
        }
    }
`;

function usePhoneQuery(userName) {
    return useQuery(
        phoneQuery,
        {
            variables: {
                UserName: userName,
            },
        },
    );
}

const statsQuery = gql`
    query getStats(
        $UserName: String!) {
        getStats(UserName: $UserName) {
            StatId
            StatName
            StatDate
            StatValue
        }
    }`;

function useStatsQuery(userName) {
    return useQuery(
        statsQuery,
        {
            variables: {
                UserName: userName,
            },
        },
    );
}

const forgotUsernameQuery = gql`
    query forgotUsername(
        $Email: String!) {
        forgotUsername(Email: $Email) {
            Email
        }
    }
`;

const grantorOrgsQuery = gql`
    query getGrantors(
        $UserName: String!) {
        getGrantorOrgsFromUserName(UserName: $UserName) {
            OrganizationId
            OrganizationName
            Address1
            Address2
            City
            State
            PostalCode
            Country
            ContactEmail
            PublicKey
            Verified
            Active
            Api {
                ApiKey
            }
        }
    }
`;

function useGrantorOrgsQuery(userName) {
    return useQuery(
        grantorOrgsQuery,
        {
            variables: {
                UserName: userName,
            },
        },
    );
}

const adminsForOrgQuery = gql`
    query getAdminsForOrg(
        $OrganizationId: String!) {
        getAdminsForOrg(OrganizationId: $OrganizationId) {
            OrganizationId
            UserName
            User {
                GivenName
                FamilyName
                Email
            }
        }
    }
`;

function useAdminsForOrgQuery(organizationId) {
    return useQuery(
        adminsForOrgQuery,
        {
            variables: {
                OrganizationId: organizationId,
            },
        },
    );
}

const futureMintersForOrgQuery = gql`
    query getFutureMintersForOrg(
        $OrganizationId: String!) {
        getFutureMintersForOrg(OrganizationId: $OrganizationId) {
            OrganizationId
            Email
            AdminGivenName
            AdminFamilyName
        }
    }
`;

function useFutureMintersForOrgQuery(organizationId) {
    return useQuery(
        futureMintersForOrgQuery,
        {
            variables: {
                OrganizationId: organizationId,
            },
        },
    );
}

const grantorsForOrgQuery = gql`
    query getGrantorsForOrg(
        $OrganizationId: String!) {
        getGrantorsForOrg(OrganizationId: $OrganizationId) {
            OrganizationId
            UserName
            User {
                GivenName
                FamilyName
                Email
            }
        }
    }
`;

function useGrantorsForOrgQuery(organizationId) {
    return useQuery(
        grantorsForOrgQuery,
        {
            variables: {
                OrganizationId: organizationId,
            },
        },
    );
}

const grantorsQuery = gql`
    query getGrantors(
        $UserName: String!) {
        getGrantorsFromUserName(UserName: $UserName) {
            UserName
            OrganizationId
            Organization {
                OrganizationName
            }
        }
    }
`;

function useGrantorsQuery(userName) {
    return useQuery(
        grantorsQuery,
        {
            variables: {
                UserName: userName,
            },
        },
    );
}

const grantRequestsQuery = gql`
    query getGrantRequests(
        $UserName: String!) {
        getGrantRequests(UserName: $UserName) {
            UserName
            OrganizationId
            GivenName
            FamilyName
            Title
            Email
            Phone
            LinkedIn
            Completed
            CreatedDate
            RequestId
            Referrer
        }
    }
`;

function useGrantRequestsQuery(userName) {
    return useQuery(
        grantRequestsQuery,
        {
            variables: {
                UserName: userName,
            },
        },
    );
}

const grantStatsQuery = gql`
    query getGrantStats(
        $UserName: String!,
        $UserOrOrg: String!) {
        getGrantStats(UserName: $UserName, UserOrOrg: $UserOrOrg) {
            UserName
            ReportDate
            TimePeriod
            NumberOfAwardsGranted
            ByAwardName
            ByDate
            ByRecipient
            ByGrantor
        }
    }
`;

// We have overloaded userName to support OrganizationIds as well
function useGrantStatsQuery(userName, statType) {
    const UserOrOrg = statType ?? 'user';
    return useQuery(
        grantStatsQuery,
        {
            variables: {
                UserName: userName,
                UserOrOrg,
            },
        },
    );
}

const logoImageQuery = gql`
    query getLogoImage(
        $OrganizationId: String!,
        $LogoImageId: String!) {
        getLogoImage(
            OrganizationId: $OrganizationId,
            LogoImageId: $LogoImageId) {
            OrganizationId
            LogoImageId
            LogoImageLocation
            LogoImageName
        }
    }
`;

function useLogoImageQuery(organizationId, logoImageId) {
    return useQuery(
        logoImageQuery,
        {
            variables: {
                OrganizationId: organizationId,
                LogoImageId: logoImageId,
            },
        },
    );
}

const logoImagesQuery = gql`
    query getLogoImages(
        $OrganizationId: String!) {
        getLogoImages(OrganizationId: $OrganizationId) {
            OrganizationId
            LogoImageId
            LogoImageLocation
            LogoImageName
        }
    }
`;

function useLogoImagesQuery(organizationId) {
    return useQuery(
        logoImagesQuery,
        {
            variables: {
                OrganizationId: organizationId,
            },
        },
    );
}

const logoImagesForUserQuery = gql`
    query getLogoImagesForUser(
        $UserName: String!) {
        getLogoImagesForUser(UserName: $UserName) {
            OrganizationId
            LogoImageId
            LogoImageLocation
            LogoImageName
        }
    }
`;

function useLogoImagesForUserQuery(userName) {
    return useQuery(
        logoImagesForUserQuery,
        {
            variables: {
                UserName: userName,
            },
        },
    );
}

const isAdminQuery = gql`
    query isAdmin(
        $UserName: String!) {
        isAdmin(UserName: $UserName) {
            UserName
            IsAnAdmin
        }
    }
`;

function useAdminQuery(userName) {
    return useQuery(
        isAdminQuery,
        {
            variables: {
                UserName: userName,
            },
        },
    );
}

const orgAdminsQuery = gql`
    query getOrgAdminsFromUserName(
        $UserName: String!) {
        getOrgAdminsFromUserName(UserName: $UserName) {
            UserName
            OrganizationId
            Organization {
                OrganizationName
                Api {
                    ApiKey
                }
            }
        }
    }
`;

function useOrgAdminsQuery(userName) {
    return useQuery(
        orgAdminsQuery,
        {
            variables: {
                UserName: userName,
            },
        },
    );
}

const userQuery = gql`
    query getUser(
        $UserName: String!) {
        userFromUserName(
            UserName: $UserName) {
            UserName
            GivenName
            FamilyName
            Email
            EmailOptOut
            Created
            Phone
        }
    }
`;

function useUserQuery(userName) {
    return useQuery(
        userQuery,
        {
            variables: {
                UserName: userName,
            },
        },
    );
}

const getCalendarForMinterAndOrg = gql`
    query getCalendarForMinterAndOrg(
        $OwnerUserName: String!, $OrganizationId: String!) {
        getCalendarForMinterAndOrg(
            OwnerUserName: $OwnerUserName, OrganizationId: $OrganizationId) {
            OwnerUserName
            EventId
            OrganizationId
            EventName
            EventDate
            TargetEmail
            Recurrence
            Target {
                GivenName
                FamilyName
            }
            Organization {
                OrganizationName
            }
        }
    }
`;

function useCalendarForMinterAndOrgQuery(ownerUserName, organizationId) {
    return useQuery(
        getCalendarForMinterAndOrg, {
            variables: {
                OwnerUserName: ownerUserName,
                OrganizationId: organizationId,
            },
        },
    );
}

const getCalendarsForMinter = gql`
    query getCalendarsForMinter(
        $OwnerUserName: String!) {
        getCalendarsForMinter(
            OwnerUserName: $OwnerUserName) {
            OwnerUserName
            EventId
            OrganizationId
            EventName
            EventDate
            TargetEmail
            Recurrence
            Target {
                GivenName
                FamilyName
            }
            Organization {
                OrganizationName
            }
        }
    }
`;

function useCalendarsForMinterQuery(ownerUserName) {
    return useQuery(
        getCalendarsForMinter, {
            variables: {
                OwnerUserName: ownerUserName,
            },
        },
    );
}

const getUserEmailsForOrgQuery = gql`
    query getUserEmailsForOrg(
        $OrganizationId: String!) {
        getUserEmailsForOrg(
            OrganizationId: $OrganizationId) {
            UserName
            Email
            Active
            User {
                GivenName
                FamilyName
            }
        }
    }`;

function useGetUserEmailsForOrgQuery(organizationId) {
    return useQuery(
        getUserEmailsForOrgQuery, {
            variables: {
                OrganizationId: organizationId,
            },
        },
    );
}

const getUsersQuery = gql`
    query getUsers(
        $UserName: String!) {
        getUsers(
            UserName: $UserName) {
            UserName
            GivenName
            FamilyName
            Email
            EmailOptOut
            Created
            Phone
        }
    }`;

function useGetUsersQuery(userName) {
    return useQuery(
        getUsersQuery, {
            variables: {
                UserName: userName,
            },
        },
    );
}

const organizationQuery = gql`
    query getOrganization(
        $OrganizationId: String!) {
        getOrganizationFromId(OrganizationId: $OrganizationId) {
            OrganizationName
            OrganizationId
            Address1
            Address2
            City
            State
            PostalCode
            Country
            ContactEmail
            PublicKey
            Verified
            Active
            VerificationNotes
            LicenseType
            SlackWebhookUrl
        }
    }
`;

function useOrganizationQuery(organizationId) {
    return useQuery(
        organizationQuery,
        {
            variables: {
                OrganizationId: organizationId,
            },
        },
    );
}

const organizationsQuery = gql`
    query getOrganizations($UserName: String!) {
        getOrganizations(UserName: $UserName) {
            OrganizationName
            OrganizationId
            Address1
            Address2
            City
            State
            PostalCode
            Country
            ContactEmail
            PublicKey
            Verified
            Active
            VerificationNotes
            LicenseType
            SlackWebhookUrl
        }
    }
`;

function useOrganizationsQuery(userName) {
    return useQuery(
        organizationsQuery, {
            variables: {
                UserName: userName,
            },
        },
    );
}

const resendInvitationQuery = gql`
    query resendInvitation($AwardId: String!) {
        resendInvitation(AwardId: $AwardId) {
            AwardId
        }
    }
`;

const teamQuery = gql`
    query getTeam($ManagerUserName: String!) {
        getTeam(ManagerUserName: $ManagerUserName) {
            ManagerUserName
            Email
            OrganizationId
            Organization {
                OrganizationName
            }
            User {
                UserName
                GivenName
                FamilyName
            }
            MostRecentAward {
                AwardId
                AwardName
                Accepted
                AcceptDate
                GrantDate
                NFTPermalink
                NFTLocation
            }
        }
    }
`;

function useTeamQuery(managerUserName) {
    return useQuery(
        teamQuery,
        {
            variables: {
                ManagerUserName: managerUserName,
            },
            notifyOnNetworkStatusChange: true,
            errorPolicy: 'all',
        },
    );
}

const teamFromManagerOrgQuery = gql`
    query getTeamFromManagerOrg(
        $ManagerUserName: String!,
        $OrganizationId: String!) {
        getTeamFromManagerOrg(
            ManagerUserName: $ManagerUserName,
            OrganizationId: $OrganizationId,
        ) {
            ManagerUserName
            Email
            OrganizationId
            User {
                UserName
                GivenName
                FamilyName
            }

        }
    }  
`;

function useTeamFromManagerOrgQuery(managerUserName, organizationId) {
    return useQuery(
        teamFromManagerOrgQuery,
        {
            variables: {
                ManagerUserName: managerUserName,
                OrganizationId: organizationId,
            },
        },
    );
}

const templateQuery = gql`
    query getTemplates(
        $UserName: String!) {
            getTemplatesFromUserName(UserName: $UserName) {
            TemplateName
            OrganizationId
            NFTLocation
            RecipientName {
                left
                top
                height
                width
                fontFamily
                fontSize
                stroke
                angle
            }
            AwardDescription {
                left
                top
                height
                width
                fontFamily
                fontSize
                stroke
                angle
            }
            GrantDate {
                left
                top
                height
                width
                fontFamily
                fontSize
                stroke
                angle
            }
            TemplateId
            Width
            Height
            AwardName {
                left
                top
                height
                width
                fontFamily
                fontSize
                stroke
                angle
            }
            NFTSerialNumber {
                left
                top
                height
                width
                fontFamily
                fontSize
                stroke
                angle
            }
            OrganizationLogo {
                left
                top
                height
                width
            }
            LaudLogo {
                left
                top
                height
                width             
            }
        }
    }
`;

function useTemplateQuery(userName) {
    return useQuery(
        templateQuery,
        {
            variables: {
                UserName: userName,
            },
        },
    );
}

const templateByOrganizationQuery = gql`
    query getTemplatesByOrganization(
        $OrganizationId: String!) {
            getTemplatesByOrganization(OrganizationId: $OrganizationId) {
            TemplateName
            OrganizationId
            NFTLocation
            RecipientName {
                left
                top
                height
                width
                fontFamily
                fontSize
                stroke
                angle
            }
            AwardDescription {
                left
                top
                height
                width
                fontFamily
                fontSize
                stroke
                angle
            }
            GrantDate {
                left
                top
                height
                width
                fontFamily
                fontSize
                stroke
                angle
            }
            TemplateId
            Width
            Height
            AwardName {
                left
                top
                height
                width
                fontFamily
                fontSize
                stroke
                angle
            }
            NFTSerialNumber {
                left
                top
                height
                width
                fontFamily
                fontSize
                stroke
                angle
            }
            OrganizationLogo {
                left
                top
                height
                width
            }
            LaudLogo {
                left
                top
                height
                width
            }
        }
    }
`;

function useTemplatesByOrganizationQuery(organizationId) {
    return useQuery(
        templateByOrganizationQuery,
        {
            variables: {
                OrganizationId: organizationId,
            },
        },
    );
}

const verificationQuery = gql`
    query getVerification(
        $UserName: String!,
        $ResourceName: String!,
        $VerificationCode: String!) {
        getVerification(UserName: $UserName, ResourceName: $ResourceName, VerificationCode: $VerificationCode) {
            UserName
            ResourceName
            VerificationCode
            VerifiedDate
        }
    }
`;

function useVerificationQuery(userName, resourceName, verificationCode) {
    return useQuery(
        verificationQuery,
        {
            variables: {
                UserName: userName,
                ResourceName: resourceName,
                VerificationCode: verificationCode,
            },
        },
    );
}

export {
    forgotUsernameQuery,
    getSignatureQuery,
    grantorsForOrgQuery,
    logoImageQuery,
    organizationQuery,
    resendInvitationQuery,
    userQuery,
    acceptAwardMutation,
    addEmailMutation,
    addEventMutation,
    addGrantorMutation,
    addLogoImageMutation,
    addNomination,
    addOrgAdminMutation,
    addTeamMemberMutation,
    createConsumerProductInterestMutation,
    createGrantRequestMutation,
    createOrganizationMutation,
    createTemplateMutation,
    deactivateEmailMutation,
    deleteEmailMutation,
    deleteEventMutation,
    deleteFutureMinterMutation,
    deleteGrantorMutation,
    deleteLogoImageMutation,
    deleteOrgAdminMutation,
    deleteOrganizationMutation,
    deleteTeamMemberMutation,
    deleteTemplateMutation,
    deleteUserMutation,
    emailOptOutMutation,
    grantAwardMutation,
    markGrantRequestCompleted,
    revokeAwardMutation,
    sendAndCollectEmailMutation,
    uploadToArweaveMutation,
    updateUserEmailMutation,
    updateUserNameMutation,
    updateUserOptOutMutation,
    verifyEmailMutation,
    useAdminQuery,
    useAdminsForOrgQuery,
    useAwardsQuery,
    useAwardQuery,
    useAwardsByOrganizationQuery,
    useAwardsForOrganizationQuery,
    useEmailQuery,
    useFutureMintersForOrgQuery,
    useCalendarForMinterAndOrgQuery,
    useCalendarsForMinterQuery,
    useGetUserEmailsForOrgQuery,
    useGetUsersQuery,
    useGrantorsForOrgQuery,
    useGrantRequestsQuery,
    useGrantStatsQuery,
    useGrantorOrgsQuery,
    useGrantorsQuery,
    useLogoImagesForUserQuery,
    useLogoImageQuery,
    useLogoImagesQuery,
    useOrgAdminsQuery,
    useOrganizationQuery,
    useOrganizationsQuery,
    usePhoneQuery,
    useStatsQuery,
    useTeamFromManagerOrgQuery,
    useTeamQuery,
    useTemplateQuery,
    useTemplatesByOrganizationQuery,
    useUserQuery,
    useVerificationQuery,
};
