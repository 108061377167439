function redirectUponSignIn() {
    // Redirect to home page upon sign in unless there is a nextPage search param
    const pathname = window.location.pathname.toLowerCase();
    const nextPageString = 'nextPage=';
    const nextPageIndex = window.location.search.indexOf(nextPageString);
    if (nextPageIndex > -1) {
        const nextPage = window.location.search.substr(
            nextPageIndex + nextPageString.length,
        );
        window.location.assign(nextPage);
    } else if (pathname === '/signin' || pathname === '/signin/') {
        window.location.assign('/');
    }
}

export {
    // eslint-disable-next-line import/prefer-default-export
    redirectUponSignIn,
};
