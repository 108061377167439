import { memo, useState } from 'react';

import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';

// NB - the alert bar scrolls. If we want to make it sticky we can use
// style={{ position: 'fixed', width: '100%' }} but the Amplify Login UI floats
// so that'll appear over top of it. Maybe that's better than not seeing it though?
// For padding shifts for navbar, eliminating gaps -- set top padding to zero for now
function AlertBar(props) {
    const classString = props.inContainer ? '' : 'pt-0';
    const [show, setShow] = useState(true);

    if (show && props.message) {
        return (
            <Alert
              className={classString}
              variant="danger"
              dismissible={props.dismissible}
              onClose={() => setShow(false)}
              style={props.style}
            >
                {!props.inContainer && (<br />)}
                {props.message}
            </Alert>
        );
    }
    return null;
}

AlertBar.propTypes = {
    message: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]).isRequired,
    inContainer: PropTypes.bool,
    dismissible: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    style: PropTypes.object,
};

AlertBar.defaultProps = {
    inContainer: false,
    dismissible: false,
    style: {},
};

export default memo(AlertBar);
