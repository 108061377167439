import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import LoadingSpinner from './LoadingSpinner';
import { useAdminQuery } from './Queries';
import useUserContext from './UserContext';

function AdminRoute(props) {
    const Component = props.component;
    const contextUser = useUserContext();
    const userId = contextUser?.attributes?.sub || '';
    let isAdmin = contextUser?.attributes?.admin || false;
    // Rely on the query if cognito attribute not true
    const { data, error, loading: loadingAdmin } = contextUser?.attributes?.admin == null
        ? useAdminQuery(userId)
        : { data: { isAdmin }, error: false, loading: false };

    if (!isAdmin && loadingAdmin) return <LoadingSpinner className="mt-5" />;

    if (userId && data && data.isAdmin && data.isAdmin.IsAnAdmin) {
        isAdmin = data.isAdmin.IsAnAdmin;
    }

    const destination = '/signIn';
    if (!userId) {
        const search = `?nextPage=${props.location.pathname}${props.location.search}`;
        return <Redirect to={{ pathname: destination, search }} />;
    }

    if (error) { // Avoid infinite redirect loops by not setting nextPage
        return <Redirect to={{ pathname: destination }} />;
    }

    if (!isAdmin) {
        return (
            <Redirect
              to={{
                  pathname: '/error',
                  state: { message: 'Page not found' },
              }}
            />
        );
    }
    return <Component match={props.computedMatch} />;
}

AdminRoute.propTypes = {
    // eslint-disable-next-line
    component: PropTypes.object.isRequired,
    // eslint-disable-next-line
    computedMatch: PropTypes.object,
    // Uncomment below if redirecting based on path
    // path: PropTypes.string.isRequired,
    location: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
};

AdminRoute.defaultProps = {
    computedMatch: null,
    location: '',
};

export default AdminRoute;
