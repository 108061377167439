import { memo, useState } from 'react';

import Collapse from 'react-bootstrap/Collapse';

// NB - we copied the react-bootstrap-drawer npm module to ./Drawer and
// added a forwardRef in DrawerOverflow to fix a react warning
import './Drawer/style.css';
import { Drawer } from './Drawer/Drawer';

import useUserContext from './UserContext';

// NB - it'd be great to have the SideBar not scroll with the rest of the page
// And, it'd be nice to parameterize this so that you pass in the Drawer.Nav
// contents. That way, we could use it on the right or left and update it
// as you navigate. See https://github.com/SimpleSigner/react-bootstrap-drawer
// and https://stackoverflow.com/questions/22752637/how-to-implement-responsive-independently-scrolling-panes-in-bootstrap

// NB - adding Collapse is tempting but v1 of the component does use the
// forwardRef necessary to prevent react from complaining about an error in
// Transition (which is used by Collapse)

const SideBar = (props) => {
    const [open, setOpen] = useState(false);
    const handleToggle = () => setOpen(!open);
    const user = useUserContext();
    const isAdmin = user.attributes.admin || false;
    const isOrgAdmin = user.attributes.orgAdmin || false;
    const canGrant = user.attributes.canGrant || false;

    return (
        <Drawer {...props}>
            <Drawer.Toggle onClick={handleToggle} />
            <Collapse in={open}>
                <Drawer.Overflow>
                    <Drawer.ToC>
                        <Drawer.Header href="/" style={{ fontSize: '1.25rem' }}>{' '}</Drawer.Header>
                        <Drawer.Nav>
                            <>
                                <Drawer.Item href="/">Home</Drawer.Item>
                                {user.attributes.canGrant && (
                                    <>
                                        <Drawer.Item href="/team">Team</Drawer.Item>
                                        <Drawer.Item href="/calendar">Calendar</Drawer.Item>
                                        <Drawer.Item href="/grant">Mint Awards</Drawer.Item>
                                        <Drawer.Item href="/bulkMint">Bulk Mint Awards</Drawer.Item>
                                        <Drawer.Item href="/grantlist">Minting History</Drawer.Item>
                                        <Drawer.Item href="/grantstats">Minting Stats</Drawer.Item>
                                        <Drawer.Item href="/revoke">Revoke Awards</Drawer.Item>
                                    </>
                                )}
                                {(isOrgAdmin || canGrant) && (
                                    <Drawer.Item href="/listOrganizations">Admin</Drawer.Item>
                                )}
                                {isAdmin && (
                                    <Drawer.Item href="/admin">Site Admin</Drawer.Item>
                                )}
                            </>
                        </Drawer.Nav>
                    </Drawer.ToC>
                </Drawer.Overflow>
            </Collapse>
        </Drawer>
    );
};

export default memo(SideBar);
